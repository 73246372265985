var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('validation-observer',{ref:"addFormvalidate"},[_c('b-form',{staticClass:"mt-2",staticStyle:{"width":"60%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Accreditation  Name","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Course Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.addForm.name),callback:function ($$v) {_vm.$set(_vm.addForm, "name", $$v)},expression:"addForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Accreditation  Website Link","label-for":"blog-edit-slug"}},[_c('validation-provider',{attrs:{"name":"In-Link Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-slug"},model:{value:(_vm.addForm.link),callback:function ($$v) {_vm.$set(_vm.addForm, "link", $$v)},expression:"addForm.link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Accreditation Logo ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewE2",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":("https://lpcentre.com/" + (_vm.addForm.image)),"height":"110","width":"170"}})],1),_c('b-media-body',[_c('b-card-text',{staticClass:"my-50"}),_c('div',{staticClass:"d-inline-block"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tab Image ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Logo ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputE2",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRendererTab},model:{value:(_vm.addForm.image),callback:function ($$v) {_vm.$set(_vm.addForm, "image", $$v)},expression:"addForm.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tab Image Alt Text","label-for":"blog-edit-title"}},[_c('b-form-input',{attrs:{"id":"alt-image"},model:{value:(_vm.addForm.alt_image),callback:function ($$v) {_vm.$set(_vm.addForm, "alt_image", $$v)},expression:"addForm.alt_image"}})],1)],1)],1)],1)],1)]),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v(" Image ")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewEl",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":("https://lpcentre.com/" + (_vm.addForm.certificate)),"height":"110","width":"170"}})],1),_c('b-media-body',[_c('b-card-text',{staticClass:"my-50"}),_c('div',{staticClass:"d-inline-block"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":" Image ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":" Image  ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.addForm.certificate),callback:function ($$v) {_vm.$set(_vm.addForm, "certificate", $$v)},expression:"addForm.certificate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Description","label-for":"blog-content"}},[_c('validation-provider',{attrs:{"name":"Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{ref:"descriptionEditor",attrs:{"options":_vm.getEditorOptions('descriptionEditor')},model:{value:(_vm.addForm.description),callback:function ($$v) {_vm.$set(_vm.addForm, "description", $$v)},expression:"addForm.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"data-testid":"accreditation-update-button","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }